import "bootstrap/dist/css/bootstrap.css";
// css from components will take precedence over default styles as a result of placing bootstrap first
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(  
  <App />
);

